import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { collection, doc, getDocs, getDoc, writeBatch, arrayUnion } from 'firebase/firestore';
import { useAuth } from './AuthContext';
import { db } from '../firebase';  // Import Firestore

const LearningContext = createContext();

export const useLearning = () => useContext(LearningContext);

export const LearningProvider = ({ children }) => {
  const { user, loading: authLoading } = useAuth();  // Check if user is authenticated and loading state from AuthContext
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [nextLesson, setNextLesson] = useState(null);
  const [nextWorkbook, setNextWorkbook] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state for LearningContext
  const [error, setError] = useState(null);

  // Memoized fetch function to avoid re-rendering
  const fetchUserAndCourseData = useCallback(async () => {
    if (!user) return;
  
    setLoading(true);
    setError(null);
  
    try {
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);
      const userData = userDoc.exists() ? userDoc.data() : {};
  
      const finishedLessons = userData.finishedLessons || [];
      const finishedWorkbooks = userData.finishedWorkbooks || [];
  
      if (user.courseID) {
        const courseDocRef = doc(db, 'courses', user.courseID);
        const courseDoc = await getDoc(courseDocRef);
  
        if (courseDoc.exists()) {
          const courseData = { id: courseDoc.id, ...courseDoc.data() };
  
          const modulesSnapshot = await getDocs(collection(courseDocRef, 'modules'));
          const modulesData = await Promise.all(
            modulesSnapshot.docs.map(async (moduleDoc) => {
              const moduleData = { id: moduleDoc.id, ...moduleDoc.data() };
  
              const lessonsSnapshot = await getDocs(collection(courseDocRef, 'modules', moduleDoc.id, 'lessons'));
              const lessonsData = lessonsSnapshot.docs.map((lessonDoc) => ({
                id: lessonDoc.id,
                ...lessonDoc.data(),
              }));
  
              const workbooksSnapshot = await getDocs(collection(courseDocRef, 'modules', moduleDoc.id, 'workbooks'));
              const workbooksData = workbooksSnapshot.docs.map((workbookDoc) => ({
                id: workbookDoc.id,
                ...workbookDoc.data(),
              }));
  
              return {
                ...moduleData,
                lessons: lessonsData,
                workbooks: workbooksData,
              };
            })
          );
  
          const fullCourseData = {
            ...courseData,
            modules: modulesData,
          };
  
          // Store the full course data in localStorage
          localStorage.setItem(`course-${user.courseID}-${user.uid}`, JSON.stringify(fullCourseData));
          setSelectedCourse(fullCourseData);
  
          // **Flatten lessons and workbooks for easier access**
          const allLessons = modulesData.flatMap((module) => module.lessons || []);
          const allWorkbooks = modulesData.flatMap((module) => module.workbooks || []);
  
          // Filter out finished lessons and workbooks
          const unfinishedLessons = allLessons.filter(
            (lesson) => !finishedLessons.some((finished) => finished.lessonID === lesson.id)
          );
          const unfinishedWorkbooks = allWorkbooks.filter(
            (workbook) => !finishedWorkbooks.some((finished) => finished.workbookID === workbook.id)
          );
  
          // Set the next lesson and workbook
          setNextLesson(unfinishedLessons[0] || null);
          setNextWorkbook(unfinishedWorkbooks[0] || null);
  
          console.log('Flattened lessons:', allLessons);
          console.log('Flattened workbooks:', allWorkbooks);
        } else {
          setError('Selected course does not exist.');
        }
      }
    } catch (err) {
      console.error('Error fetching user or course data:', err);
      setError('Failed to fetch course data.');
    } finally {
      setLoading(false);
    }
  }, [user]);
  
  
  
  // Only fetch data if the user is authenticated and no longer loading
  useEffect(() => {
    if (!authLoading && user) {
      fetchUserAndCourseData();
    } else {
      setLoading(false);  // Ensure loading is set to false if no user is present
    }
  }, [user, authLoading, fetchUserAndCourseData]);

  const finishLesson = async (lessonID, lessonStats = {}) => {
    try {
      const userRef = doc(db, 'users', user.uid);
      const batch = writeBatch(db);  // Initialize a batch for Firestore writes

      const newFinishedLesson = {
        lessonID,
        ...lessonStats,
        date: new Date().toISOString(),
      };

      batch.update(userRef, {
        finishedLessons: arrayUnion(newFinishedLesson),
        currentLessonID: lessonID,
      });

      await batch.commit();  // Commit the batch write

      fetchUserAndCourseData();  // Refetch user and course data to update the next lesson
    } catch (error) {
      console.error('Error finishing lesson:', error);
      setError('Failed to finish the lesson.');
    }
  };

  const finishWorkbook = async (workbookID, workbookStats = {}) => {
    try {
      const userRef = doc(db, 'users', user.uid);
      const batch = writeBatch(db);  // Initialize a batch for workbooks

      const newFinishedWorkbook = {
        workbookID,
        ...workbookStats,
        date: new Date().toISOString(),
      };

      batch.update(userRef, {
        finishedWorkbooks: arrayUnion(newFinishedWorkbook),
        currentWorkbookID: workbookID,
      });

      await batch.commit();  // Commit the batch write

      fetchUserAndCourseData();  // Refetch user and course data to update the next workbook
    } catch (error) {
      console.error('Error finishing workbook:', error);
      setError('Failed to finish the workbook.');
    }
  };

  return (
    <LearningContext.Provider
      value={{
        selectedCourse,
        nextLesson,
        nextWorkbook,
        finishLesson,
        finishWorkbook,
        loading,
        error,
      }}
    >
      {!loading && children}
    </LearningContext.Provider>
  );
};
