import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { registerWithEmail, registerWithGoogle } from '../auth';

const Register = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [photoURL, setPhotoURL] = useState('');
  const [nativeLanguage, setNativeLanguage] = useState('');
  const [job, setJob] = useState('');
  const [contact, setContact] = useState('');
  const [currentStep, setCurrentStep] = useState(1);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("Registering user:", email);
      await registerWithEmail(email, password, {
        displayName,
        photoURL,
        nativeLanguage,
        job,
        contact,
      });
      console.log("Registration successful");
      onClose(); // Close the overlay after successful registration
      navigate('/profile'); // Navigate to the profile page
    } catch (error) {
      console.error("Registration error:", error);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await registerWithGoogle();
      onClose(); // Close the overlay after successful sign-in
      navigate('/profile'); // Navigate to the profile page
    } catch (error) {
      console.error("Google Sign-In error:", error);
    }
  };

  const nextStep = () => setCurrentStep(currentStep + 1);
  const prevStep = () => setCurrentStep(currentStep - 1);

  return (
    <form onSubmit={handleSubmit}>
      {currentStep === 1 && (
        <div className="form-step">
          <h2>Step 1: Account Details</h2>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required />
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required />
          <input type="text" value={displayName} onChange={(e) => setDisplayName(e.target.value)} placeholder="Name" required/>
          <input type="file" onChange={(e) => setPhotoURL(URL.createObjectURL(e.target.files[0]))} placeholder="Photo" />
          <button className="btn" type="button" onClick={nextStep}>Next</button>
          <button type="button" className="btn-gg" onClick={handleGoogleSignIn}>
            Register with Google
          </button>
        </div>
      )}
      {currentStep === 2 && (
        <div className="form-step">
          <h2>Step 2: Profile Details</h2>
          <input type="text" value={nativeLanguage} onChange={(e) => setNativeLanguage(e.target.value)} placeholder="Native Language" />
          <input type="text" value={job} onChange={(e) => setJob(e.target.value)} placeholder="Job" />
          <input type="text" value={contact} onChange={(e) => setContact(e.target.value)} placeholder="Contact" />
          <button className="btn" type="button" onClick={prevStep}>Back</button>
          <button className="btn-gg" type="submit">Register</button>
        </div>
      )}
    </form>
  );
};

export default Register;
